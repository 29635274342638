import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

export const updateRangeInitial = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating initial range: ", error);
  }
};

export const updateOfferInitial = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating initial offer: ", error);
  }
};

export const updateOfferPost = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating post offer: ", error);
  }
};

export const updateOfferPostFinal = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating final post offer: ", error);
  }
};

export const updateOfferExpected = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating expected offer: ", error);
  }
};

export const updateStatusExpired = async (company, deal_id, data) => {
  const pricingDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "pricing"
  );
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating expired status: ", error);
  }
};

export const solicitarCotizacion = async (company, deal_id, data) => {
  const actionsDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "actions"
  );
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error updating quote request: ", error);
  }
};

export const solicitarRevision = async (company, deal_id, data) => {
  const actionsDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "actions"
  );
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error updating review request: ", error);
  }
};

export const aceptarPropuesta = async (company, deal_id, data) => {
  const actionsDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "actions"
  );
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error accepting proposal: ", error);
  }
};

export const rechazarPropuesta = async (company, deal_id, data) => {
  const actionsDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "actions"
  );
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error rejecting proposal: ", error);
  }
};

export const updateFieldOperation = async (data, deal_id, user) => {
  try {
    const takeDocRef = doc(
      db,
      "companies",
      user?.company,
      "deals",
      deal_id,
      "data",
      "take"
    );

    await updateDoc(takeDocRef, {
      "amount_to_pay.money": data,
      "amount_to_pay.user": user,
    });
  } catch (error) {
    console.error("Error actualizando la consignación: ", error);
    return error;
  }
};

export const formatNumber = (value) => {
  if (value == null) return "";
  const formattedValue = value
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return formattedValue;
};
