// MainFilterDateComponent.js
import React, { useState } from "react";
import { Col, Dropdown, Row, Button, Form } from "react-bootstrap";
import { MdFilterList } from "react-icons/md";
import { BiCalendarEvent } from "react-icons/bi";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import ModalFilterDateNewComponent from "./ModalFilterDateNewComponent";
import { useFilters } from "../../../context/FilterContext";


const MainFilterDateComponent = () => {
  const isMobile = useMediaQuery("(max-width: 500px)");
  const { filters, setFilters } = useFilters();
  const [openFilters, setOpenFilters] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState([
    {
      startDate: filters.init_date ? new Date(filters.init_date) : new Date(),
      endDate: filters.end_date ? new Date(filters.end_date) : new Date(),
      key: "selection",
    },
  ]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const openCalendarModal = () => setOpenModal(true);

  const handleLocation = (e) => {
    setFilters({ ...filters, branch_id: e.target.value });
  };

  const cleanFilter = () => {
    setFilters({
      ...filters,
      init_date: "",
      end_date: "",
      is_confirm: "",
      branch_id: "",
      status: [],
    });
    setOpenFilters(false);
    setState([{ startDate: new Date(), endDate: new Date(), key: "selection" }]);
  };

  const applyDateFilter = (selectedDates) => {
    const { from, to } = selectedDates;
    setFilters({
      ...filters,
      init_date: formatDate(from),
      end_date: formatDate(to),
    });
    setOpenModal(false);
  };

  return (
    <Dropdown show={openFilters} onToggle={(isOpen) => setOpenFilters(isOpen)}>
      <Dropdown.Toggle className="filter-button" id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="filter-menu"
        style={{ width: isMobile ? "90vw" : "50vw" }}
      >
        <Row className="w-100 mx-0">
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }} className="my-2">
            <p className="filter-title my-2">Fecha del turno</p>
            <div
              className="reminder-input d-flex align-items-center justify-content-between mb-2"
              onClick={openCalendarModal}
              style={{ cursor: "pointer", width: "100%", height: "38px" }}
            >
              <p className="reminder-text-placeholder" style={{ color: "#1B2130" }}>
                {filters.init_date && filters.end_date
                  ? `${new Date(filters.init_date).toLocaleDateString()} - ${new Date(filters.end_date).toLocaleDateString()}`
                  : "Seleccionar tiempo"}
              </p>
              <div className="me-2">
                <BiCalendarEvent fontSize="22px" color="#1B2130" />
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }} className="my-2">
            <p className="filter-title my-2">Sucursal</p>
            <Form.Select
              className="filter-select"
              value={filters?.branch_id}
              onChange={handleLocation}
              style={{ cursor: "pointer" }}
            >
              <option value="">Todas</option>
              <option value={1}>Warnes</option>
              <option value={2}>Independencia</option>
              <option value={3}>Llavallol</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }} className="my-2">
            <p className="filter-title my-2">Confirmado</p>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-2 mt-2">
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, is_confirm: e.target.value })
                    }
                    checked={filters.is_confirm === "si"}
                    inline
                    label="Sí"
                    value="si"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, is_confirm: e.target.value })
                    }
                    checked={filters.is_confirm === "no"}
                    inline
                    value="no"
                    label="No"
                    name="group1"
                    type={type}
                  />
                </div>
              ))}
            </Form>
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col className="d-flex justify-content-end my-2">
            <Button className="mb-2" onClick={cleanFilter}>
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Dropdown.Menu>
      <ModalFilterDateNewComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        setState={setState}
        state={state}
        onApply={applyDateFilter}
      />
    </Dropdown>
  );
};

export default MainFilterDateComponent;
