import React from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { useDeals } from "../../../context/DealsContext";
import { action_status } from "../../../constants/actionStatus";
import { useCompany } from "../../../context/CompanyContext";
import TableActionsComponent from "./TableActionsComponent";
import { colors } from "../../../styles/colors";
import { MdKeyboardArrowDown } from "react-icons/md";
import UserAttendingTooltipComponent from "./UserAttendingTooltipComponent";
import { ImWhatsapp } from "react-icons/im";
import { teams } from "../../../constants/teams";
import { useUser } from "../../../context/UserContext";
import Swal from "sweetalert2";
import { updateDealInProcess } from "../services/servicesTableDeals";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  EmptyStateComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import KeyImg from "../../Deal/images/key.svg";
import Consignment from "../../Deal/images/consignment-icon.svg";

export default function DefaultViewTableComponent() {
  const { user } = useUser();
  let isPageWide = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { loadMore, increaseDeals, deals, loadingDeals } = useDeals();
  const { allUsers } = useCompany();

  const headTable = [
    "Fecha",
    "Hora",
    "CS",
    "Estado",
    "Cliente",
    "Patente",
    "Vehiculo",
    "Oferta",
    "",
  ];

  const rowStyleDealInProsess = {
    backgroundColor: colors.primary.secundaryTransparent,
    cursor: "pointer",
  };
  const rowStyleDeal = {
    backgroundColor: colors.neutral.light,
    cursor: "pointer",
  };
  const verDeal = (id) => {
    navigate(`/comments/${id}`);
  };
  let userTeamInProcess = [];

  const canUserViewDeal = (data) => {
    if (data?.current_user_team) {
      userTeamInProcess = Object.keys(data?.current_user_team);
      const userTeamExist = userTeamInProcess.includes(user?.team);
      if (!userTeamExist) {
        return true;
      } else {
        if (!data.current_user_team[user?.team]) {
          return true;
        } else if (data.current_user_team[user?.team] === user.uid) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };
  let showName = [];

  const showTooltip = (data) => {
    let usersNames = [];
    for (let key in data?.current_user_team) {
      if (data?.current_user_team[key] !== null) {
        Object.values(data?.current_user_team).forEach((value) => {
          if (value) {
            const foundUser = allUsers?.find((user) =>
              value?.includes(user.id)
            );
            if (foundUser) {
              usersNames.push(foundUser);
            } else {
              // Agregar el id del usuario undefined
              usersNames.push({
                team: Object.keys(data?.current_user_team),
                name: value,
              });
            }
          }
        });
        showName = usersNames;
        return true;
      }
    }
  };

  let userNameInProcess;

  function handleClick(event, data) {
    if (user?.team === teams.ADMIN || !data?.current_user_team) {
      updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
    } else if (canUserViewDeal(data)) {
      if (data.current_user_team[user?.team] !== user.uid) {
        updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
      }
    } else {
      event.preventDefault();
      const userIdInProcess = Object.values(data?.current_user_team);
      userNameInProcess = allUsers.find((user) =>
        userIdInProcess.includes(user.id)
      );
      Swal.fire({
        title: "No puede trabajar en este deal",
        text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }
  }

  return (
    <>
      {loadingDeals ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {deals?.length === 0 ? (
            <div className="mt-3 h-100 deals-table-style">
              <EmptyStateComponent />
            </div>
          ) : (
            <Table hover responsive className="mt-3 deals-table-style">
              <thead>
                <tr>
                  {headTable.map((data, i) => (
                    <th key={i} className=" textHead pt-3 pb-3">
                      {data}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {deals?.map((data, i) => (
                  <tr
                    key={i}
                    onClick={(e) => handleClick(e, data)}
                    style={
                      !data?.current_user_team
                        ? rowStyleDeal
                        : !data?.current_user_team[user?.team]
                          ? rowStyleDeal
                          : data?.current_user_team[user?.team] !== user.uid
                            ? rowStyleDealInProsess
                            : rowStyleDeal
                    }
                  >
                    <td onClick={() => verDeal(data.id)}>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {data.date}
                      </p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">{data.hour}</p>
                    </td>
                    <td>
                      <p className="text-content-table-deals">
                        {" "}
                        {allUsers.map((dataAllUser, index) =>
                          dataAllUser.id === data.user_team?.CS
                            ? dataAllUser?.name
                            : null
                        )}
                      </p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">{data?.status}</p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">{data?.name}</p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">{data?.car_id}</p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">{data?.car}</p>
                    </td>
                    <td onClick={() => verDeal(data.id)}>
                      <p className="text-content-table-deals">
                        {data?.deal_data?.last_offer?.value}
                      </p>
                    </td>
                    <td
                      onClick={() => {
                        if (data.trengo_ticket_id) {
                          window.open(
                            `https://app.trengo.com/tickets/${data.trengo_ticket_id}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "flex-end" }}>
                        {data.trengo_ticket_id ? (
                          <p className="text-content-table-deals">
                            <ImWhatsapp fontSize="24" color="#00BB2D" />
                          </p>
                        ) : null}
                        {showTooltip(data) ? (
                          <UserAttendingTooltipComponent showName={showName} />
                        ) : null}
                        {data?.deal_data?.permute && (
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 200 }}
                            overlay={
                              <Popover id="popover-basic">
                                <Popover.Body>Permuta</Popover.Body>
                              </Popover>
                            }
                          >
                            <img src={KeyImg} alt="key-img" className="key-img ms-2" />
                          </OverlayTrigger>

                        )}
                        {data?.deal_data?.consignment && (
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 200 }}
                            overlay={
                              <Popover id="popover-basic">
                                <Popover.Body>Consignación</Popover.Body>
                              </Popover>
                            }
                          >
                            <img
                              src={Consignment}
                              alt="consignment-icon"
                              className="me-2 ms-2"
                              style={{ width: "30px", height: "30px" }}
                            />
                          </OverlayTrigger>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
                {!loadMore ? null : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onClick={increaseDeals}
                    >
                      <div
                        className={`d-flex align-items-center justify-content-${isPageWide ? "center" : "start"
                          }`}
                      >
                        <p
                          style={{
                            margin: "0px",
                            color: colors.primary.primary,
                            fontSize: "14px",
                            marginLeft: isPageWide ? "" : "100px",
                          }}
                        >
                          Cargar más
                        </p>
                        <MdKeyboardArrowDown
                          color={colors.primary.primary}
                          fontSize="14"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
}
